@import '../../styles/styles.scss';

.#{$prefix}--page-spacing {
  display: flex;
  flex-direction: column;
}

.#{$prefix}--page {
  @include safe-area;
  margin: 0 auto;
}

.#{$prefix}--page-content-container {
  padding: 0 $spacing-small;
  margin: auto;
  max-width: 1200px;

  @include breakpoint-up(md) {
    padding: 0 $spacing-medium;
    padding-top: 24px;
  }

  @include breakpoint-down(md) {
    padding-top: 58px;
  }
}

.#{$prefix}--page-content {
  padding-bottom: $spacing-medium;
  margin: $spacing-large auto;
  @extend .#{$prefix}--page-spacing;

  @include breakpoint-up(md) {
    padding-bottom: $spacing-xx-large;
  }
}

.#{$prefix}--page-no-header-top {
  padding-top: $spacing-xxx-large;
}