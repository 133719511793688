@import '../../../../../styles/styles.scss';

.#{$prefix}--page-mobile-navbar {
  align-items: center;
  display: flex;
  padding: 8px;
  // TODO: update min-height when nimbus resolves button heigth with border
  min-height: 58px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @include safe-area;
}


.#{$prefix}--page-mobile-navbar__toolbar {
  flex: 1 1 33.3333334%;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  text-align: right;
}

.#{$prefix}--page-mobile-navbar__toolbar>.nimbus--link {
  padding: $spacing-x-small;
}

.#{$prefix}--page-navbar__back>.nimbus--button {
  background-color: transparent;
  border-color: transparent;
  color: $color-blue-dark-pure;
  padding: $spacing-x-small;

  &:focus {
    box-shadow: 0 0 0 1px $color-blue-dark-light;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: transparent;
    border-color: $color-blue-dark-light;
  }
}