@mixin safe-area() {
  @supports (padding-top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);

    padding-top: var(--safe-area-inset-top);
  }

  @supports (padding-top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);

    padding-top: var(--safe-area-inset-top);
  }
}