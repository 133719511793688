.nimbus--data-table__row {
  background-color: white;
}

.nimbus--inner-container {
  width: 100%;
  max-width: 400px;
}

.nimbus--full-screen-center-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  padding: 24px 0;

  &>.nimbus--stack-wrapper {
    width: 96%;
    max-width: 700px;
  }
}

.nimbus--app-logo {
  width: 200px;
}

.nimbus--app-icon {
  width: 50px;
  height: 50px;
}

.nimbus--app-logo {
  width: 180px;
  height: 47px;
}

.nimbus--full-width {
  width: 100%;
}

.nimbus--modal.is-visible {
  padding: 24px;
}

.nimbus--data-table {
  overflow: visible;
}

.nimbus--popover-wrapper {
  width: 180px;
}

@media (min-width: 672px) {
  .nimbus--modal-wrapper {
    max-width: 600px;
  }
}

input[name="TelefoneFiscalDDD"] {
  max-width: 60px;
}

.nimbus--data-table__header .nimbus--text {
  font-weight: 700 !important;
}

.nimbus--callout-card__content {
  white-space: break-spaces;
}